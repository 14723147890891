@import '~react-image-gallery/styles/scss/image-gallery.scss';
// TODO: Maybe something like this for font splitting if bundling all the fonts gets out of control for size...
// https://stackoverflow.com/questions/46835825/conditional-css-in-create-react-app
// Enroller branding
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

// Educatius branding
@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../fonts/TheFuture-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../fonts/TheFuture-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../fonts/TheFuture-Black.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'TheFuture';
  src: local('TheFuture'), url('../fonts/TheFuture-Bold.otf') format('opentype');
  font-weight: 700;
}

@media print {
  * {
    float: none !important;
  }
  section {
    padding-top: 15px;
    display: block !important;
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }
}

body {
  background-color: #fafafa;
}

.image-gallery {
  width: 100%;
  height: auto;
  .image-gallery-content {
    .image-gallery-slide {
      .image-gallery-image {
        width: 100%;
        height: 600px;
        max-height: 600px;
        overflow: hidden;
        object-position: center center;
      }
    }
    &.fullscreen .image-gallery-slide .image-gallery-image {
      height: auto;
      max-height: 100vh;
    }
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.marker {
  background-image: url('../../public/map-marker.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 48px;
  height: 64px;
  cursor: pointer;
}

img.map-marker-logo {
  padding: 8px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}
